let config = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "forwood-europe-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-europe-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "eu-west-1",
        URL: "https://6yf2jjua55fbphqh7qbozejooi.appsync-api.eu-west-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://t6dsmevomk.execute-api.eu-west-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://jgfj4lx09i.execute-api.eu-west-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.europe.forwoodsafety.com",
        WEBSOCKET: "wss://a1l7hepeqj.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_OFCFdW11F",
        IDENTITY_POOL_ID: "eu-west-1:80b5d324-ab29-4ff1-b859-0a654a3851b8",
        USERPOOL_HOSTED_DOMAIN: "forwood-europe-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.europe.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.europe.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::992382576218:role/prod-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.13",
        HOSTNAME: "id.europe.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".europe.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.europe.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
